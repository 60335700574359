<template>
  <b-modal
    v-model="visible"
    cancel-variant="outline-secondary"
    :ok-title="$t('Сохранить')"
    no-close-on-backdrop
    centered
    :title="title"
    @close="$emit('close', true)"
    @cancel="$emit('close', true)"
    @ok.prevent="save"
  >
    <validation-observer
      ref="form"
      #default="{invalid}"
    >
      <b-form
        class="auth-login-form mt-2"
      >
        <b-form-group
          :label="$t('Izoh')"
          label-for="comment"
        >
          <validation-provider
            #default="{ errors }"
            :name="$t('Izoh')"
            rules="required"
          >
            <b-form-textarea
              id="comment"
              v-model="form.comment"
              :state="errors.length > 3 ? false:null"
              :name="$t('Izoh')"
              rows="3"
              max-rows="6"
              :placeholder="$t('Izoh')"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
        <b-col class="passport-upload">
          <div
            v-loading="loading"
            class="text-center"
          >
            <template
              v-if="
                form.enter_document_url &&
                  form.enter_document_url.slice(-3) == 'pdf'
              "
            >
              <div @click="openPdf(storageUrl + form.enter_document_url)">
                <pdf
                  :src="storageUrl + form.enter_document_url"
                  height="162"
                  width="162"
                  class="img-uploaded"
                />
              </div>
            </template>
            <template v-else-if="form.enter_document_url">
              <img
                :src="storageUrl + form.enter_document_url"
                height="162"
                width="162"
                class="img-uploaded"
              >
            </template>
            <template v-else>
              <img
                src="@/assets/images/svg/fileuploader-placeholder.svg"
                class="img-placeholder"
                alt="image"
                width="162"
                @click="$refs.addressPassport.click()"
              >
            </template>
          </div>
          <div class="text-center">
            <input
              ref="addressPassport"
              accept="image/jpeg, image/jpg, image/png, application/pdf"
              type="file"
              class="d-none"
              @change="(e) => onChangeFile(e)"
            >
            <span
              class="upload-label"
              @click="$refs.addressPassport.click()"
            >
              <template v-if="form.enter_document_url">
                <feather-icon
                  icon="CheckIcon"
                  size="12"
                />
                {{ $t("Qora ro'yhatga kiritish uchun asos") }}
                <feather-icon
                  icon="Trash2Icon"
                  size="12"
                />
              </template>
              <template v-else>
                <feather-icon
                  icon="PlusSquareIcon"
                  size="12"
                />
                {{ $t("Qora ro'yhatga kiritish uchun asos") }}
              </template>
            </span>
          </div>
        </b-col>
      </b-form>
    </validation-observer>
  </b-modal>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { showToast } from '@/utils/toast'
import { required } from '@validations'
import { mapActions } from 'vuex'
import { clearObject } from '@/utils'

export default {
  name: 'Create',
  components: {
    ValidationProvider, ValidationObserver,
  },
  props: {
    visible: {
      type: Boolean, default: false,
    },
    title: {
      type: String, default: 'Izoh',
    },
  },
  data() {
    return {
      form: {
        comment: null,
        enter_document_url: '',
      },
      loading: false,
      required,
    }
  },
  computed: {
    storageUrl() {
      return `${process.env.VUE_APP_BASE_URL}storage/`
    },
  },
  watch: {
    visible(newVal) {
      if (!newVal) setTimeout(() => { clearObject(this.form) }, 200)
    },
  },
  mounted() {

  },
  methods: {
    async save() {
      const valid = await this.validationForm()
      if (valid) {
        this.$emit('confirmed', this.form)
      } else {
        showToast('warning', this.$t('Заполните необходимые поля'))
      }
    },

    async validationForm() {
      let validated = false
      await this.$refs.form.validate().then(success => {
        validated = success
      })
      return validated
    },
    openPdf(link) {
      window.open(link, '_blank')
    },
    async onChangeFile(event) {
      if (event.target.files[0].size <= 21360000) {
        this.loading = true
        const fileData = new FormData()
        fileData.append('file', event.target.files[0])
        fileData.append('directory', 'blacklist-documents')
        this.fileUpload(fileData)
          .then(res => {
            this.form.enter_document_url = res.data.path
          })
          .catch(err => {
            showToast('danger', err.data.message, 'XIcon')
          })
          .finally(() => {
            this.loading = false
          })
      } else {
        showToast(
          'danger',
          this.$t('Файл ҳажми 20 МБ дан кичик бўлиши лозим'),
          'XIcon',
        )
      }
    },
    ...mapActions({
      storeItem: 'role/store',
      fileUpload: 'client/fileUpload',
      updateItem: 'role/update',
    }),
  },
}
</script>

<style scoped>

</style>
